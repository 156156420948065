import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { apiNaoLogado } from '../../services/index';
import { Growl } from 'primereact/growl';
import palette from '../../theme/palette';

const salvaLocalStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};
const Sigin = props => {
  let growl = null;
  useEffect(() => {
    if (sessionStorage.getItem('@admin-token')) {
      props.history.push('/logado');
    }
  }, []);
  const [state, setState] = useState({ userName: '', password: '' });
  const autentica = async () => {
    const { userName, password } = state;
    if (userName && password) {
      const { data } = await apiNaoLogado.post('/auth', { userName, password });

      const { user, token } = data;
      if (token) {
        salvaLocalStorage('@admin-user', JSON.stringify(user));
        salvaLocalStorage('@admin-token', token);
        props.history.push('/logado');
      } else
        growl.show({
          severity: 'error',
          summary: 'Login inválido',
          detail: 'Usuário e senha incorretos'
        });
    } else
      growl.show({
        severity: 'error',
        summary: 'Login inválido',
        detail: 'Preencha todos os campos!'
      });
  };
  return (
    <>
      <Growl ref={el => (growl = el)} baseZIndex={999} style={{ opacity: 1 }} />
      <div
        style={{
          paddingTop: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Card
          style={{
            maxWidth: '100em'
          }}>
          <div>
            <h2>Login</h2>
          </div>
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 8
              }}>
              <label>Usuário:</label>
              <InputText
                id="in"
                value={state.userName}
                style={{ width: 400 }}
                onChange={e => setState({ ...state, userName: e.target.value })}
              />
            </span>
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 8
              }}>
              <label>Senha:</label>
              <Password
                feedback={false}
                value={state.password}
                style={{ width: 400 }}
                onChange={e => setState({ ...state, password: e.target.value })}
              />
            </span>
            <Button
              onClick={autentica}
              style={{ marginTop: 32, backgroundColor: palette.primary.main }}
              label="Entrar"
              icon="pi pi-sign-in"
              iconPos="right"
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default Sigin;
