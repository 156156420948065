import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { apiLogado } from '../../../../services/index';
import { useSnackbar } from 'notistack';
// import { Container } from './styles';

export default function Create() {
  const { enqueueSnackbar } = useSnackbar();
  const showSnackBar = (mensagem, variant = 'success') => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, {
      variant
    });
  };
  const [state, setState] = useState({
    isLogedd: false,
    nomeSala: '',
    tokenUser: ''
  });
  const handleCadastroDisciplina = async () => {
    const { nomeSala } = state;
    if (nomeSala) {
      await apiLogado
        .post(
          '/cria-sala',
          { nomeSala },
          {
            headers: {
              Authorization: 'Bearer '.concat(
                sessionStorage.getItem('@admin-token')
              )
            }
          }
        )
        .then(() => {
          setState({
            ...state,
            nomeSala: ''
          });
          showSnackBar('Sala criada com sucesso!');
        })
        .catch(() => {
          showSnackBar(
            'Erro ao criar sala, verifique os dados e tente novamete!',
            'error'
          );
        });
    } else {
      showSnackBar('Seus dados não podem ser vazios', 'error');
    }
  };
  return (
    <Card style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 8
          }}>
          <label>Nome da guia:</label>
          <InputText
            value={state.nomeSala}
            onChange={e => setState({ ...state, nomeSala: e.target.value })}
          />
        </span>
        <div style={{ marginTop: 32 }}>
          <Button
            style={{
              width: 250
            }}
            label="Salvar"
            icon="pi pi-save"
            iconPos="right"
            onClick={handleCadastroDisciplina}
          />
        </div>
      </div>
    </Card>
  );
}
