import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { apiNaoLogado } from './../../services/index';
import { Button } from 'primereact/button';
import { PLANILHAS } from '../../constants/index';
import { TabPanel, TabView } from 'primereact/tabview';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const getData = async () => {
    const planilhas = await PLANILHAS();
    const LINK_AVA = 'https://virtual.uninta.edu.br/course/view.php?id=';
    await apiNaoLogado.get('/').then(items => {
      const data = items.data.map(item => {
        const estado = item.ESTADO;
        return {
          ...item,
          link: item.nomeDisc,
          estado: (
            <>
              <Message
                severity={
                  estado === 'NÃO CONTÉM CONTEÚDO'
                    ? 'error'
                    : estado === 'CONTÉM CONTEÚDO'
                    ? 'success'
                    : 'info'
                }
                text={estado}
              />
            </>
          ),
          buttonLink: (
            <Button
              onClick={() => {
                window.open(LINK_AVA.concat(item.idDisc));
              }}
              className=""
              icon="pi pi-external-link"
            />
          )
        };
      });
      const dataFiltrada = planilhas.map(item => {
        const { value } = item;
        return data.filter(item => {
          return item.sala === value && item.sala !== 'POLO FLÓRIDA';
        });
      });
      setState({ dataDisciplinas: dataFiltrada });
    });
  };
  const [state, setState] = useState({
    dataDisciplinas: []
  });
  const classes = useStyles();
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className={classes.root}>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 15
        }}
        header={
          <div>
            <Card
              style={{ maxWidth: '50vw' }}
              header={
                <h2 style={{ textAlign: 'center', paddingTop: 50 }}>
                  Catálogo de Disciplinas EAD
                </h2>
              }>
              <br />
              <p style={{ paddingLeft: 10 }}>
                Caro coordenador e professores abaixo temos a lista de todas as
                disciplinas em EAD cadastradas até o momento, e um link de
                inscrição para professor que irá acompanhar as disciplinas em
                Ambiente virtual. Favor seguir as orientações abaixo:
              </p>
              <br />
              <p style={{ paddingLeft: 10 * 2 }}>
                <ol>
                  <li>
                    Para acessar a sala virtual o professor deverá clicar no
                    link da disciplina presente na tabela abaixo;
                  </li>
                  <li>Realizar o login no Ambiente virtual;</li>
                  <li>
                    Utilizar a senha de auto inscrição na sala:{' '}
                    <strong>professoruninta</strong>
                  </li>
                  <li>
                    A sua inscrição em Ambiente Virtual terá a duração de 6
                    (seis) meses, caso for necessário a permanência do professor
                    por mais tempo o professor poderá refazer a auto inscrição
                    na em sala.
                  </li>
                </ol>
              </p>
            </Card>
          </div>
        }>
        <TabView style={{ width: '100%' }}>
          {state.dataDisciplinas.map(salas => {
            if (salas[0]) {
              const nome = salas[0].sala;
              return (
                <TabPanel header={nome}>
                  <DataTable
                    value={salas}
                    paginator={true}
                    rows={100}
                    responsive={false}>
                    <Column
                      field="idDisc"
                      header="Id da Disciplina"
                      sortable
                      filter
                    />
                    <Column field="nomeDisc" header="Disciplina" filter />
                    <Column
                      field="buttonLink"
                      style={{ width: '5em' }}
                      header="Link"
                    />
                    <Column
                      field="estado"
                      header="ESTADO"
                      sortable={false}
                      style={{ textAlign: 'center' }}
                      body={state.dataDisciplinas.Estado}
                    />
                  </DataTable>
                </TabPanel>
              );
            } else {
              return <TabPanel></TabPanel>;
            }
          })}
        </TabView>
      </Card>
    </div>
  );
};

export default Dashboard;
