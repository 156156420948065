import React, { useState, useEffect } from 'react';
import { ESTADOS_DISCIPLINA, PLANILHAS } from './../../../../constants';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import palette from '../../../../theme/palette';
import { Dropdown } from 'primereact/dropdown';
import { apiLogado } from '../../../../services/index';
import { InputSwitch } from 'primereact/inputswitch';
import { useSnackbar } from 'notistack';

export default function Update() {
  const { enqueueSnackbar } = useSnackbar();
  const showSnackBar = (mensagem, variant = 'success') => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, {
      variant
    });
  };
  const [state, setState] = useState({
    textoBusca: '',
    newId: '',
    idDiscIsEditable: false,
    disciplinasBanco: [],
    disciplinaSelecionada: {},
    valueDropdow: '',
    planilhas: []
  });
  useEffect(() => {
    PLANILHAS().then(planilhas => {
      setState({ ...state, planilhas });
    });
  }, []);
  const handleSearchById = async () => {
    const { textoBusca } = state;
    let disciplinaSelecionada = {};
    let disciplinasBanco = [];
    if (textoBusca) {
      const res = await apiLogado.post(
        'search-by-id',
        { idDisc: parseInt(textoBusca) },
        {
          headers: {
            Authorization: 'Bearer '.concat(
              sessionStorage.getItem('@admin-token')
            )
          }
        }
      );
      if (!res.data.length) {
        showSnackBar(
          'O ID informado não foi encontrado na base de dados!',
          'error'
        );
        setState({
          ...state,
          idDisc: '',
          newId: '',
          nomeDisc: '',
          estado: '',
          sala: '',
          idDiscIsEditable: false,
          disciplinaSelecionada: {}
        });
      } else {
        if (res.data.length === 1) {
          disciplinaSelecionada = {
            ...res.data[0],
            nomeRef: res.data[0].nomeDisc
          };
        } else {
          // const { nomeDisc, estado, sala } = res.data[0];
          disciplinasBanco = res.data.map((item, i) => {
            return {
              label: item.nomeDisc,
              value: i,
              nomeRef: item.nomeDisc,
              ...item
            };
          });
        }
        setState({
          ...state,
          disciplinasBanco,
          disciplinaSelecionada
        });
        showSnackBar('Disciplinas encontradas');
      }
    } else showSnackBar('Preencha corretamento o campo de busca!', 'error');
  };
  const handleUpdateDisc = async () => {
    let {
      idDisc,
      nomeDisc,
      estado,
      sala,
      nomeRef
    } = state.disciplinaSelecionada;
    let { newId } = state;
    if (idDisc && nomeDisc && estado && sala) {
      newId = newId ? newId : idDisc;
      await apiLogado
        .put(
          '/update',
          { idDisc, nomeDisc, estado, sala, newId, nomeRef },
          {
            headers: {
              Authorization: 'Bearer '.concat(
                sessionStorage.getItem('@admin-token')
              )
            }
          }
        )
        .then(() => {
          showSnackBar('Atualizado com sucesso!');
          setState({
            ...state,
            newId: '',
            textoBusca: '',
            idDiscIsEditable: false,
            disciplinasBanco: [],
            disciplinaSelecionada: {
              idDisc: null,
              nomeDisc: null,
              estado: null,
              sala: null,
              nomeRef: null
            }
          });
        });
    } else {
      showSnackBar('Os campos não podem está vazios', 'error');
    }
  };
  const handleChangeDropbox = e => {
    const selected = state.disciplinasBanco[e.value];

    setState({
      ...state,
      valueDropdow: e.value,
      disciplinaSelecionada: selected
    });
  };
  return (
    <Card style={{ width: '100%' }}>
      <div>
        <h2>Atualização da disciplina</h2>
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 8
          }}>
          <label>ID da Disciplina:</label>
          <div>
            <InputText
              value={state.textoBusca}
              onChange={e => {
                setState({
                  ...state,
                  textoBusca: e.target.value
                });
              }}
              keyfilter="pint"
            />
            <Button
              onClick={handleSearchById}
              icon="pi pi-search"
              style={{ marginLeft: 8 }}
            />
            <Dropdown
              style={{
                display: state.disciplinasBanco.length ? 'flex' : 'none',
                marginTop: 8
              }}
              value={state.valueDropdow}
              options={state.disciplinasBanco}
              onChange={e => handleChangeDropbox(e)}
              placeholder="Selecione a disciplina"
            />
          </div>
        </span>
      </div>
      {state.disciplinaSelecionada.idDisc && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 4
            }}>
            <label>ID da Disciplina:</label>
            <InputText
              disabled
              value={state.disciplinaSelecionada.idDisc}
              onChange={e =>
                setState({
                  ...state,
                  disciplinaSelecionada: {
                    ...state.disciplinaSelecionada,
                    idDisc: e.target.value
                  }
                })
              }
              keyfilter="pint"
            />
          </span>
          {state.idDiscIsEditable && (
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 4
              }}>
              <label style={{ color: palette.primary.main }}>Novo ID</label>
              <InputText
                tooltip="Certifique-se de que não está inserindo um id de uma outra disciplina!"
                id="in"
                value={state.newId}
                onChange={e =>
                  setState({
                    ...state,
                    newId: e.target.value
                  })
                }
                style={{
                  width: 250,
                  borderColor: palette.primary.main
                }}
                keyfilter="pint"
              />
            </span>
          )}
          {state.disciplinaSelecionada.idDisc && (
            <InputSwitch
              style={{ marginBottom: 8 }}
              tooltip="Editar ID Disciplina"
              checked={state.idDiscIsEditable}
              onChange={e =>
                setState({
                  ...state,
                  idDiscIsEditable: e.value,
                  newId: ''
                })
              }
            />
          )}
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 8
            }}>
            <label>Nome da Disciplina:</label>
            <InputText
              value={state.disciplinaSelecionada.nomeDisc}
              onChange={e => {
                setState({
                  ...state,
                  disciplinaSelecionada: {
                    ...state.disciplinaSelecionada,
                    nomeDisc: e.target.value
                  }
                });
              }}
            />
          </span>
          <div>
            <Dropdown
              style={{ width: 250 }}
              value={state.disciplinaSelecionada.estado}
              options={ESTADOS_DISCIPLINA}
              onChange={e => {
                setState({
                  ...state,
                  disciplinaSelecionada: {
                    ...state.disciplinaSelecionada,
                    estado: e.value
                  }
                });
              }}
              placeholder="Selecione o Estado da Disciplina"
            />
          </div>
          <div style={{ marginTop: 8 }}>
            <Dropdown
              style={{ width: 250 }}
              value={state.disciplinaSelecionada.sala}
              options={state.planilhas}
              onChange={e => {
                setState({
                  ...state,
                  disciplinaSelecionada: {
                    ...state.disciplinaSelecionada,
                    sala: e.value
                  }
                });
              }}
              placeholder="Selecione planilha"
            />
          </div>
          <div style={{ marginTop: 32 }}>
            <Button
              style={{
                width: 250
              }}
              label="Salvar"
              icon="pi pi-save"
              iconPos="right"
              onClick={handleUpdateDisc}
            />
          </div>
        </div>
      )}
    </Card>
  );
}
