import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import palette from './../../../../theme/palette';

// import { Container } from './styles';
const dataDognout = dados => {
  return {
    datasets: [
      {
        data: [dados.contem, dados.naoContem, dados.emAndamento],
        backgroundColor: [
          palette.success.main,
          '#dc3545',
          palette.secondary.dark
        ],
        borderWidth: 8,
        borderColor: palette.white,
        hoverBorderColor: palette.white
      }
    ],
    labels: ['CONTÉM CONTEÚDO', 'NÃO CONTÉM CONTEÚDO', 'EM ANDAMENTO']
  };
};
const options = {
  legend: {
    display: true
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 50,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  }
};

export default function Graphs({ data }) {
  if (data) {
    const { contem, naoContem, emAndamento } = data;
    return (
      <div style={{ height: '50%' }}>
        <Doughnut
          data={dataDognout({
            contem,
            naoContem,
            emAndamento
          })}
          options={options}
          redraw
        />
      </div>
    );
  }
  return <div />;
}
