import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { apiLogado } from '../../../../services/';
import { Button } from 'primereact/button';
import { styles } from './style';
import { Dropdown } from 'primereact/dropdown';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';

export default function Delete() {
  const { enqueueSnackbar } = useSnackbar();
  const showSnackBar = (mensagem, variant = 'success') => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, {
      variant
    });
  };
  const [state, setState] = useState({
    textoBusca: '',
    valueDropdow: '',
    nomesDisc: [],
    selecionadoParaDelete: { nome: null, id: null }
  });
  const [stateDialog, setStateDialog] = useState(false);

  const handleButtonSearch = async () => {
    await apiLogado
      .post(
        '/search-by-id',
        { idDisc: state.textoBusca },
        {
          headers: {
            Authorization: 'Bearer '.concat(
              sessionStorage.getItem('@admin-token')
            )
          }
        }
      )
      .then(({ data }) => {
        if (data.length) {
          let nomesDisc = [];
          let selecionadoParaDelete = {};
          console.log(data[0].nomeDisc, data.length);
          if (data.length === 1) {
            selecionadoParaDelete = {
              id: state.textoBusca,
              nome: data[0].nomeDisc
            };
          } else
            nomesDisc = data.map((item, i) => {
              return { label: item.nomeDisc, value: i, ...item };
            });

          setState({ ...state, nomesDisc, selecionadoParaDelete });
        } else showSnackBar('Id não encontrado', 'error');
      });
  };
  const handleExcluirDisciplina = async () => {
    const { id, nome } = state.selecionadoParaDelete;

    await apiLogado
      .put(
        '/delete-disciplina',
        { idDisc: id, nomeDisc: nome },
        {
          headers: {
            Authorization: 'Bearer '.concat(
              sessionStorage.getItem('@admin-token')
            )
          }
        }
      )
      .then(t => {
        showSnackBar('Excluído com sucesso!');
        setStateDialog(false);
        setState({ ...state, selecionadoParaDelete: {}, nomesDisc: [] });
      });
  };

  return (
    <div style={styles.root}>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <label>ID da Disciplina:</label>
          <div style={{ display: 'flex' }}>
            <InputText
              tooltip="ID da Disciplina"
              keyfilter="int"
              value={state.textoBusca}
              onChange={e => setState({ ...state, textoBusca: e.target.value })}
            />
            <Button
              icon="pi pi-search"
              onClick={handleButtonSearch}
              style={styles.button}
            />
            <Dropdown
              style={{
                display: state.nomesDisc.length > 0 ? 'flex' : 'none'
              }}
              value={state.valueDropdow}
              options={state.nomesDisc}
              onChange={e => {
                const selected = state.nomesDisc[e.value];
                const selecionadoParaDelete = {
                  nome: selected.label,
                  id: selected.idDisc
                };
                setState({
                  ...state,
                  valueDropdow: e.value,
                  selecionadoParaDelete
                });
                console.log(state.nomesDisc[e.value]);
              }}
              placeholder="Selecione o Estado da Disciplina"
            />
          </div>
        </span>
      </div>
      <div style={{ display: state.selecionadoParaDelete.id ? null : 'none' }}>
        <List>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '50vw', flexGrow: 1 }}>
              <ListItem
                style={{
                  backgroundColor: '#eee',
                  padding: 10,
                  borderRadius: 10
                }}>
                {state.selecionadoParaDelete.nome}
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => {
                      setStateDialog(true);
                    }}
                    color="secondary"
                    size="small">
                    <DeleteIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          </div>
        </List>
      </div>
      <Dialog
        onClose={() => {
          setStateDialog(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={stateDialog}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem plena certeza de que deseja{' '}
            <strong>EXCLUIR esta disciplina</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              onClick={() => {
                setStateDialog(false);
              }}
              color="primary"
              label="Cancelar"
            />
          </div>
          <div>
            <Button
              onClick={handleExcluirDisciplina}
              color="primary"
              label="Sim, desejo excluir!"
              className="p-button-danger p-button-raised"
              style={{ color: '#eee', fontWeight: 900 }}
            />
          </div>
        </DialogActions>
      </Dialog>
      {/* <div>
        <InputText
          value={state.textoBusca}
          onChange={e => setState({ textoBusca: e.target.value })}
        />
      </div> */}
    </div>
  );
}
