import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function Table({ dataDisciplinas, qntDisciplinas }) {
  if (qntDisciplinas) {
    return (
      <div
        style={{
          height: '50%',
          marginTop: 25
        }}>
        <DataTable value={dataDisciplinas}>
          <Column field="nome" header="Sala" filter sortable />
          <Column
            field="total"
            header={`Total Disciplinas - ${qntDisciplinas.contem +
              qntDisciplinas.naoContem +
              qntDisciplinas.emAndamento}`}
            sortable
            style={{ textAlign: 'center' }}
          />
          <Column
            field="contem"
            header={`CONTÉM CONTEÚDO - ${qntDisciplinas.contem}`}
            sortable
            style={{ textAlign: 'center' }}
          />
          <Column
            field="naoContem"
            header={`NÃO CONTÉM CONTEÚDO - ${qntDisciplinas.naoContem}`}
            sortable
            style={{ textAlign: 'center' }}
          />
          <Column
            field="emAndamento"
            header={`EM ANDAMENTO- ${qntDisciplinas.emAndamento}`}
            sortable
            style={{ textAlign: 'center' }}
          />
        </DataTable>
      </div>
    );
  }
  return <div />;
}
