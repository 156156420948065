import { apiNaoLogado } from './../services/index';

export const ESTADOS_DISCIPLINA = [
  { label: 'CONTÉM CONTEÚDO', value: 'CONTÉM CONTEÚDO' },
  { label: 'NÃO CONTÉM CONTEÚDO', value: 'NÃO CONTÉM CONTEÚDO' },
  { label: 'EM ANDAMENTO', value: 'EM ANDAMENTO' }
];
export const PLANILHAS = async () => {
  let planilhas = [];
  await apiNaoLogado.post('/lista-salas').then(planilhasSala => {
    console.log(planilhasSala);
    planilhasSala.data.map(item => {
      planilhas.push({ label: item.nomeSala, value: item.nomeSala });
    });
  });
  return planilhas;
};
