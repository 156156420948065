import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ESTADOS_DISCIPLINA, PLANILHAS } from '../../../../constants';
import { apiLogado } from '../../../../services/index';
import { useSnackbar } from 'notistack';
// import { Container } from './styles';

export default function Create() {
  const { enqueueSnackbar } = useSnackbar();
  const showSnackBar = (mensagem, variant = 'success') => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, {
      variant
    });
  };
  const [state, setState] = useState({
    isLogedd: false,
    idDisc: '',
    nomeDisc: '',
    estado: 'NÃO CONTÉM CONTEÚDO',
    sala: '',
    tokenUser: '',
    planilhas: []
  });
  useEffect(() => {
    PLANILHAS().then(planilhas => {
      setState({ ...state, planilhas });
    });
  }, []);
  const handleCadastroDisciplina = async () => {
    const { idDisc, nomeDisc, estado, sala } = state;
    if (idDisc && nomeDisc && estado && sala) {
      await apiLogado
        .post(
          '/cria-disciplina',
          { idDisc, nomeDisc, estado, sala },
          {
            headers: {
              Authorization: 'Bearer '.concat(
                sessionStorage.getItem('@admin-token')
              )
            }
          }
        )
        .then(() => {
          setState({
            ...state,
            idDisc: '',
            nomeDisc: '',
            estado: '',
            sala: ''
          });
          showSnackBar('Cadastrado com sucesso!');
        })
        .catch(() => {
          showSnackBar(
            'Erro ao cadastrar, verifique os dados e tente novamete!',
            'error'
          );
        });
    } else {
      showSnackBar('Seus dados não podem ser vazios', 'error');
    }
  };
  return (
    <Card style={{ width: '100%' }}>
      <div>
        <h2>Cadastro de Disciplinas</h2>
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 8
          }}>
          <label>ID da Disciplina:</label>
          <InputText
            value={state.idDisc}
            onChange={e => setState({ ...state, idDisc: e.target.value })}
            keyfilter="pint"
          />
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 8
          }}>
          <label>Nome da Disciplina:</label>
          <InputText
            value={state.nomeDisc}
            onChange={e => setState({ ...state, nomeDisc: e.target.value })}
          />
        </span>
        <div>
          <Dropdown
            style={{ width: 250 }}
            value={state.estado}
            options={ESTADOS_DISCIPLINA}
            onChange={e => {
              setState({ ...state, estado: e.value });
            }}
            placeholder="Selecione o Estado da Disciplina"
          />
        </div>
        <div style={{ marginTop: 8 }}>
          <Dropdown
            style={{ width: 250 }}
            value={state.sala}
            options={state.planilhas}
            onChange={e => {
              setState({ ...state, sala: e.value });
            }}
            placeholder="Selecione planilha"
          />
        </div>
        <div style={{ marginTop: 32 }}>
          <Button
            style={{
              width: 250
            }}
            label="Salvar"
            icon="pi pi-save"
            iconPos="right"
            onClick={handleCadastroDisciplina}
          />
        </div>
      </div>
    </Card>
  );
}
