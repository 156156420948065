import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { apiLogado } from '../../services/index';
import { TabView, TabPanel } from 'primereact/tabview';

import Delete from './components/Delete';
import Graphs from './components/Graphs';
import Table from './components/Table';
import Update from './components/Update/Update';
import { SnackbarProvider } from 'notistack';
import Create from './components/Create/Create';
import Dashboard from './components/Dashboard/Dashboard';
// import { Professores } from '../index';
import Listagem from './../ListagemGeral/Listagem';
import CreateSala from './components/CreateSala/CreateSala';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: '#999'
  }
}));

const Logado = ({ history }) => {
  const [userName, setUserName] = useState('1');
  let growl = null;
  const getData = async () => {
    const token = 'Bearer '.concat(sessionStorage.getItem('@admin-token'));
    setState({ ...state, tokenUser: token });

    await apiLogado
      .post('/vl', undefined, {
        headers: {
          Authorization: token
        }
      })
      .then(({ data }) => {
        if (data.error) {
          sessionStorage.clear();
          history.push('/admin');
        } else {
          // const user = JSON.parse(sessionStorage.getItem('@admin-user'));
          console.log();
          setUserName(
            JSON.parse(sessionStorage.getItem('@admin-user')).username
          );
          setState({ ...state, isLogedd: true });
        }
      });
  };

  const [state, setState] = useState({
    isLogedd: false,
    idDisc: '',
    nomeDisc: '',
    estado: 'NÃO CONTÉM CONTEÚDO',
    sala: '',
    tokenUser: ''
  });

  const classes = useStyles();
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {state.isLogedd && (
        <div className={classes.root}>
          <TabView style={{ width: '100%' }}>
            <TabPanel
              header="Dados"
              contentStyle={{ minHeight: 'calc(100vh - 192px)' }}>
              <SnackbarProvider
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Dashboard />
              </SnackbarProvider>
            </TabPanel>
            {userName !== 'mec' ? (
              <TabPanel header="Listagem">
                <Listagem />
              </TabPanel>
            ) : (
              <TabPanel headerStyle={{ opacity: 0 }} />
            )}
            {userName !== 'mec' ? (
              <TabPanel header="Atualizar" contentStyle={{ height: '80vh' }}>
                <SnackbarProvider
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                  <Update />
                </SnackbarProvider>
              </TabPanel>
            ) : (
              <TabPanel headerStyle={{ opacity: 0 }} />
            )}
            {userName !== 'mec' ? (
              <TabPanel header="Cadastrar ID" contentStyle={{ height: '80vh' }}>
                <SnackbarProvider
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                  <Create />
                </SnackbarProvider>
              </TabPanel>
            ) : (
              <TabPanel headerStyle={{ opacity: 0 }} />
            )}
            {userName !== 'mec' ? (
              <TabPanel header="Deleção" contentStyle={{ height: '80vh' }}>
                <SnackbarProvider
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                  <Delete />
                </SnackbarProvider>
              </TabPanel>
            ) : (
              <TabPanel headerStyle={{ opacity: 0 }} />
            )}
            {userName !== 'mec' ? (
              <TabPanel header="Cria guia" contentStyle={{ height: '80vh' }}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}>
                  <CreateSala />
                </SnackbarProvider>
              </TabPanel>
            ) : (
              <TabPanel headerStyle={{ opacity: 0 }} />
            )}
          </TabView>
        </div>
      )}
    </>
  );
};

export default Logado;
