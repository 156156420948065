import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Listagem as ListagemView,
  NotFound as NotFoundView,
  Professores as ProfessoresView,
  Sigin as SigInView,
  Logado as LogadoView
} from './views';
import Dashboard from './views/Logado/components/Dashboard/Dashboard';

const Routes = () => {
  return (
    <Switch>
      {/* <Redirect exact from="/" to="/dashboard" /> */}
      <RouteWithLayout
        component={ListagemView}
        exact
        layout={MinimalLayout}
        path="/"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={ProfessoresView}
        exact
        layout={MinimalLayout}
        path="/6ecd9ef4535d1b90faffa1c0e0dd947d"
      />
      <RouteWithLayout
        component={SigInView}
        exact
        layout={MinimalLayout}
        path="/admin"
      />
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={MinimalLayout}
        path="/ids"
      />
      <RouteWithLayout
        component={LogadoView}
        exact
        layout={MainLayout}
        path="/logado"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
