import React, { useEffect, useState } from 'react';
import { apiNaoLogado } from '../../../../services/';
import { PLANILHAS, ESTADOS_DISCIPLINA } from '../../../../constants/';
import Graphs from '../Graphs';
import Table from '../Table';

export default function Dashboard() {
  // Declarações
  const [state, setState] = useState({
    disciplinasFiltradas: null,
    quantidadeDisciplinasGeral: 0,
    quantidadeSalas: 0,
    quantidadesTotaisFiltradas: null
  });
  let quantidadesTotais = { contem: 0, naoContem: 0, emAndamento: 0 };
  // funçoes
  async function getData() {
    await apiNaoLogado.get('/').then(res => {
      const { data } = res;
      // setState({ ...state, disciplinasGeral: data });
      filtraDados(data);
    });
  }
  const filtraDados = async data => {
    // filtra por sala
    const planilhas = await PLANILHAS();
    const disciplinasPorSala = planilhas.map(planilha => {
      const { value } = planilha;
      const salas = data.filter(disciplina => {
        return disciplina.sala === value;
      });

      return { nome: value, salas };
    });
    //filtra por estado
    const estadosFiltrados = disciplinasPorSala.map(disciplina => {
      const t = ESTADOS_DISCIPLINA.map(estado => {
        return disciplina.salas.filter(sala => {
          return estado.value === sala.ESTADO;
        });
      });
      return {
        nome: disciplina.nome,
        total: t[0].length + t[1].length + t[2].length,
        contem: t[0].length,
        naoContem: t[1].length,
        emAndamento: t[2].length
      };
    });

    // total filtrado
    estadosFiltrados.forEach(element => {
      quantidadesTotais = {
        nome: 'Total',
        contem: quantidadesTotais.contem + element.contem,
        naoContem: quantidadesTotais.naoContem + element.naoContem,
        emAndamento: quantidadesTotais.emAndamento + element.emAndamento
      };
    });

    const quantidadeDisciplinasGeral = data.length;
    const quantidadeSalas = disciplinasPorSala.length;

    setState({
      ...state,
      quantidadeDisciplinasGeral,
      disciplinasFiltradas: estadosFiltrados,
      quantidadeSalas,
      quantidadesTotaisFiltradas: quantidadesTotais
    });
    console.log(estadosFiltrados);
  };

  //chamadas de funçoes
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Graphs data={state.quantidadesTotaisFiltradas} />
      <Table
        dataDisciplinas={state.disciplinasFiltradas}
        qntDisciplinas={state.quantidadesTotaisFiltradas}
      />
    </>
  );
}
