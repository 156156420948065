import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { apiNaoLogado } from '../../services/index';
import { PLANILHAS } from '../../constants/index';
import { TabView, TabPanel } from 'primereact/tabview';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const getData = async () => {
    await apiNaoLogado.get('/').then(async items => {
      const data = items.data.map(item => {
        const estado = item.ESTADO;
        return {
          ...item,
          estado: (
            <>
              <Message
                severity={
                  estado === 'NÃO CONTÉM CONTEÚDO'
                    ? 'error'
                    : estado === 'CONTÉM CONTEÚDO'
                    ? 'success'
                    : 'info'
                }
                text={estado}
              />
            </>
          )
        };
      });
      const plan = await PLANILHAS();
      const dataFiltrada = plan.map(item => {
        const { value } = item;

        return data.filter(item => {
          if (item.sala === value && item.sala !== 'POLO FLÓRIDA') {
            return item.sala === value && item.sala !== 'POLO FLÓRIDA';
          }
        });
      });

      setState({ dataDisciplinas: dataFiltrada });
    });
  };
  const [state, setState] = useState({
    dataDisciplinas: []
  });

  const classes = useStyles();
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className={classes.root}>
      <TabView style={{ width: '100%' }}>
        {state.dataDisciplinas.map(salas => {
          if (salas[0]) {
            const nome = salas[0].sala;
            return (
              <TabPanel header={nome}>
                <DataTable
                  value={salas}
                  paginator={true}
                  rows={100}
                  responsive={false}>
                  <Column
                    field="idDisc"
                    header="Id da Disciplina"
                    sortable
                    filter
                  />
                  <Column field="nomeDisc" header="Disciplina" filter />
                  <Column
                    field="estado"
                    header="ESTADO"
                    sortable={false}
                    style={{ textAlign: 'center' }}
                    body={state.dataDisciplinas.Estado}
                  />
                </DataTable>
              </TabPanel>
            );
          } else {
            return <></>;
          }
        })}
      </TabView>
    </div>
  );
};

export default Dashboard;
